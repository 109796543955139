body {
  font-family: "Mulish", sans-serif;
  /* padding-right: 10px; */
  /* Approximate width of a scrollbar */
  margin: 0;
  padding: 0;

}

html {
  /* overflow-y: scroll; */
  overflow-x: hidden;
  box-sizing: border-box;
}


/* width or (height if horizontal scrollbar) */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


* {
  font-size: 14px;
  font-family: "Mulish", sans-serif !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
  line-height: normal;

}

image {
  overflow: hidden;
}

/* Hide Arrows From Input Number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-file-input {
  color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Upload files";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* Custom Library MUI
 */
.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;


}

.MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor {
  background-color: #F5F5F5 !important;

}

.MuiDataGrid-footerContainer {
  border-top: 0;
}