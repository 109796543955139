.about_left {
    /* padding-left: 50px; */

}

.about_text {
    vertical-align: baseline;
}

.h5 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}