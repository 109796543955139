.btn-go-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: #00477B;
    color: #eee;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    z-index: 100;
}

.btn-go-top:hover {
    transform: translateY(-5px);
}