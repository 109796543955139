details>summary {
    list-style: none;
}

details:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

ul li {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}
